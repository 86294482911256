<template>
  <div>
    <!-- Start Header Area -->
    <Header>

    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area -->

    <div class="pt--20 pb--120">
      <v-container>
        <v-row align="" class="row--35 team-wrapper mt--125">
          <v-col lg="5" md="5" cols="12">
            <div class="thumbnail" style="">
              <img
                  src="../assets/images/about/team_group_edited.jpg"
                  alt="About Images"
              />
            </div>
          </v-col>
          <v-col lg="7" md="7" cols="12">
            <div class="about-inner inner">
              <div class="section-title mt-4">
                <h2 class="heading-title mb-0">Wer sind wir?</h2>
                <p class="description">
                  goeldi technology ist ein junges Unternehmen im IT-Bereich. Zusammen mit dir entwickelt unser
                  Team individuelle Webapplikationen und Business Solutions, die auf deine persönlichen Bedürfnisse
                  zugeschnitten sind.
                </p>
              </div>
            </div>
            <v-row class="mt-6 mt_sm--10">
              <v-col lg="6" md="6" sm="6" cols="12" class=" ">
                <router-link to="/about" class="rn-button-style--2 mt-4 py-3">
                  <!--               <div class="icon d-inline-flex" v-html="iconSvg('users')"></div> Das Team-->
                  Schau dir das Team an
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End About Area -->

    <!-- Start Service Area -->
    <div class="service-area ptb--80 bg_image bg_grey">
      <v-container>
        <v-row>
          <v-col lg="4" md="4" sm="12" cols="12">
            <div
                class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
            >
              <h2 class="heading-title">Unser Angebot</h2>

              <div class="service-btn">
                <!--                <a class="btn-transparent rn-btn-dark" href="/service"><span class="text">Request Custom Service</span></a>-->
              </div>
            </div>
          </v-col>
          <v-col lg="8" md="8" sm="12" cols="12" class="mt_md--50">
            <ServiceTwo/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Service Area -->

    <!-- Start Portfolio Area -->
    <!-- <div class="rn-portfolio-area rn-section-gap bg_color--1">
       <div class="portfolio-sacousel-inner pb--30">
         <v-container>
           <v-row>
             <v-col lg="12">
               <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                 <h2 class="heading-title">All Works</h2>
                 <p>
                   There are many variations of passages of Lorem Ipsum
                   available,
                   <br />
                   but the majority have suffered alteration.
                 </p>
               </div>
             </v-col>
           </v-row>
         </v-container>
         <Portfolio />
       </div>
     </div> -->
    <!-- End Portfolio Area -->

    <!-- Start Counterup Area -->
    <!-- <div class="rn-counterup-area pt--25 pb--110 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-title text-center">
              <h3 class="fontWeight500">Our Fun Facts</h3>
            </div>
          </v-col>
        </v-row>

        <CounterOne />
      </v-container>
    </div> -->
    <!-- End Counterup Area -->

    <!-- Start Testimonial Area  -->
    <!-- <div class="rn-testimonial-area rn-section-gap bg_color--5">
      <v-container>
        <Testimonial />
      </v-container>
    </div> -->
    <!-- Start Testimonial Area  -->


    <!-- Start Brand Area -->
    <!--  <div class="rn-brand-area brand-separation">
        <v-container>
          <v-row>
            <v-col cols="12">
              <Brand />
            </v-col>
          </v-row>
        </v-container>
      </div> -->
    <!-- End Brand Area -->

    <!-- Start Call to action Area  -->
    <CallToAction/>
    <!-- End Call to action Area  -->
    <!-- Start Footer Area  -->
    <Footer/>
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import Header from "../components/header/Header";
import SliderOne from "../components/slider/SliderOne";
import About from "../components/about/About";
import ServiceTwo from "../components/service/ServiceTwo";
import CounterOne from "../components/counter/CounterOne";
import Testimonial from "../components/testimonial/Testimonial";
import Blog from "../components/blog/Blog";
import Brand from "../components/brand/Brand";
import CallToAction from "../components/callto-action/CallToAction";
import Footer from "../components/footer/Footer";

export default {
  components: {
    Header,
    SliderOne,
    About,
    ServiceTwo,
    CounterOne,
    Testimonial,
    Blog,
    Brand,
    Footer,
    CallToAction
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.bg_grey {
  background-color: #f5f5f5;
}
</style>
