<template>
  <footer class="footer-area footer-default">
    <div class="footer-wrapper">
      <v-row align="end" class="row--0">
        <!-- End .col -->
        <v-col md="12" sm="12" cols="12">
          <div class="footer-right" data-black-overlay="9">
            <v-row>
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12">
                <div class="footer-widget">
                  <h4>Quick Link</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList" :key="i">
                      <router-link :to="nav.to">{{ nav.navItem }}</router-link>
                    </li>
                  </ul>
                </div>
              </v-col>
              <!-- End Single Widget  -->
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <h4>Kontakt</h4>
                  <ul class="ft-link">
                    <li><p>goeldi technology<br>
                      Fabio Göldi<br>
                      Zwinglistrasse 10<br>
                      9000 St. Gallen</p></li>
                    <li>
                      <a>fabio@goelditechnology.ch</a>
                    </li>
                  </ul>

                </div>
              </v-col>
              <!-- End Single Widget  -->

              <v-col lg="12">
                <div class="copyright-text">
                  <p>
                    Copyright © {{ new Date().getFullYear() }} goeldi technology.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          navItem: "Home",
          to: "/"
        },
        {
          navItem: "Angebot",
          to: "/services",
        },
        {
          navItem: "Projekte",
          to: "/projects"
        },
        {
          navItem: "Über uns",
          to: "/about",
        },
        {
          navItem: `Kontakt`,
          to: "/contact",
        },
      ],
    };
  },
};
</script>
