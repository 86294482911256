<template>
  <div>
    <v-navigation-drawer
        class="hidden-md-and-up"
        v-model="drawer"
        temporary
        fixed
        width="60vw"
    >
      <v-list-item class="pa-2">
        <v-spacer></v-spacer>
        <v-btn large icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-item
            :ripple="false"
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- End mobile menu sidebar item list -->
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
        dark
        color="transparent"
        fixed
        elevate-on-scroll
        class="header-one-page-nav"
    >
      <router-link to="/" class="logo">
        <img src="../../assets/images/logo/logo-dark.png" width="225" class="logo-light" alt=""/>
        <img src="../../assets/images/logo/logo.png" width="225" class="logo-dark" alt=""/>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-btn
          icon
          class="ma-0 pa-0 hidden-md-and-up"
          @click="drawer = !drawer"
          v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">

        <!-- End blocks dropdown -->
        <v-btn :ripple="false" text to="/">Home</v-btn>
        <v-btn :ripple="false" text to="/services">Angebot</v-btn>
        <v-btn :ripple="false" text to="/projects">Projekte</v-btn>
        <v-btn :ripple="false" text to="/about">Über uns</v-btn>
        <v-btn :ripple="false" text to="/contact">Kontakt</v-btn>

      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";

export default {
  data: () => ({
    drawer: false,
    items: [
      {title: "Home", to: "/"},
      {title: "Angebot", to: "/services"},
      {title: "Projekte", to: "/projects"},
      {title: "About", to: "/about"},
      {title: "Kontakt", to: "/contact"},
    ],
    icon: "menu",
    closeIcon: "x",
  }),

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>

<style lang="scss">
.feather-menu {
  color: #c6c9d8;
}
</style>
