import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
        title: 'Home | goelditechnology'
    }
  },

  {
    path: '/about',
    name: 'About',
    meta: {
        title: 'Über uns | goelditechnology'
    },
    component: () => import('../views/About.vue')
  },
  {
    path: '/services',
    name: 'Angebot',
    meta: {
        title: 'Angebot | goelditechnology'
    },
    component: () => import('../views/Services.vue')
  },
  {
    path: '/projects',
    name: 'Projekte',
    meta: {
        title: 'Projekte | goelditechnology'
    },
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/projects/project-1',
    name: 'Project',
    meta: {
      title: 'Projekt 1 | goelditechnology'
    },
    component: () => import('../views/projects/project-1.vue')
  },
  {
    path: '/projects/deutschkursplattform',
    name: 'Project',
    meta: {
      title: 'Projekte | goelditechnology'
    },
    component: () => import('../views/projects/deutschkursplattform.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
        title: 'Kontakt | goelditechnology'
    },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '*',
    component: Missing,
    meta: {
        title: '404 || goelditechnology'
    },
}
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
 })


export default router
