<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <h2 class="heading-title">Über uns</h2>
            <p class="description">
              goeldi technology ist ein junges Unternehmen im IT-Bereich. Zusammen mit dir entwickelt unser Team
              individuelle Webapplikationen und Business Solutions, die auf deine persönlichen Bedürfnisse zugeschnitten
              sind.
            </p>
          </div>
          <v-row class="mt--30 mt_sm--10">
            <v-col md="2" cols="12"></v-col>
            <v-col lg="6" md="6" sm="6" cols="12" class=" ">
              <router-link to="/about" class="rn-button-style--2 mt-12 py-3 w-100">
                <!--               <div class="icon d-inline-flex" v-html="iconSvg('users')"></div> Das Team-->
                Unser Team
              </router-link>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import feather from "feather-icons";

export default {
  data() {
    return {};
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    }
    ,
  }
}
;
</script>

<style scoped>


</style>