<template>
  <v-row>
    <!-- Start Single Service  -->
    <v-col
        xl="4"
        lg="4"
        md="4"
        sm="6"
        cols="12"
        v-for="(item, i) in serviceContent"
        :key="i"
    >
      <div class="single-service text-center service__style--3 text-white">
        <div class="icon" v-html="iconSvg(item.icon)"></div>
        <!--        <div class="icon">-->
        <!--          <img :src="item.src" alt=""  />-->
        <!--        </div>-->
        <div class="content">
          <h3 class="heading-title">
            {{ item.title }}
          </h3>

        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
import feather from "feather-icons";

export default {
  data() {
    return {
      serviceContent: [
        {
          src: require("../../assets/images/icons/icon-01.png"),
          title: "Applikationen",
          desc: `Erstellen von Webseiten`,
          icon: 'code'
        },
        {
          src: require("../../assets/images/icons/icon-02.png"),
          title: "Business Solutions",
          desc: `Entwicklung von Business Applikationen mit modernsten Technologien.`,
          icon: 'box'
        },
        {
          src: require("../../assets/images/icons/icon-03.png"),
          title: "Beratung",
          desc: `message-circle`,
          icon: 'message-circle'
        },
      ],
    };
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    }
    ,
  }
}
;
</script>
